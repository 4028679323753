const formattedTranslations = [
    {
        testFieldName: "Cardiac",
        fields: [
            { fieldId: "49551-5", fieldName: "CK MB", unitAndRange: "0-5 ng/mL" },
            { fieldId: "42757-5", fieldName: "TNL", unitAndRange: "0-0.1 ng/mL" },
            { fieldId: "71427-9", fieldName: "D DIMER", unitAndRange: "0-0.5 µg/mL" },
            { fieldId: "SDB-0550", fieldName: "NT proBNP", unitAndRange: "0-125 pg/mL" },
            { fieldId: "30522-7", fieldName: "hsCRP", unitAndRange: "0-3 mg/L" },
            { fieldId: "49551-5", fieldName: "Lipid Profile", unitAndRange: "Varies by component" },
        ],
    },
    {
        testFieldName: "General",
        fields: [
            { fieldId: "", fieldName: "Blood Pressure", unitAndRange: "Systolic - 120mm Diastolic - 80mm" },
            { fieldId: "", fieldName: "Temperature", unitAndRange: "F" },
            { fieldId: "", fieldName: "Body Oxygen Saturation", unitAndRange: "Varies by method" },
            { fieldId: "", fieldName: "Height", unitAndRange: "cm" },
            { fieldId: "", fieldName: "Weight", unitAndRange: "Kg" },
            { fieldId: "", fieldName: "BMI", unitAndRange: "Kg/ m2" },
            { fieldId: "", fieldName: "Body Fat", unitAndRange: "%" },
            { fieldId: "", fieldName: "Visceral Fat", unitAndRange: "%" },
            { fieldId: "", fieldName: "Skeletal Muscle", unitAndRange: "%" },
            { fieldId: "", fieldName: "Body Age", unitAndRange: "-" },
            { fieldId: "", fieldName: "Resting Metabolism", unitAndRange: "Kcal" },
        ],
    },
    {
        testFieldName: "Diabetic",
        fields: [
            { fieldId: "4548-4", fieldName: "HbA1c", unitAndRange: "4.0-5.6%" },
            { fieldId: "SDB-0550", fieldName: "Blood Glucose", unitAndRange: "70-100 mg/dL (fasting)" },
            { fieldId: "14957-5", fieldName: "U-Albumin", unitAndRange: "<30 mg/g creatinine" },
            { fieldId: "SDB-0550", fieldName: "Glucose-non invasive", unitAndRange: ">95 %" },
        ],
    },
    {
        testFieldName: "Hormonal",
        fields: [
            { fieldId: "SDB-0190", fieldName: "β-hcG", unitAndRange: "<5 mIU/mL" },
            { fieldId: "SDB-330", fieldName: "LH", unitAndRange: "1.2-12.0 IU/L" },
            { fieldId: "83120-6", fieldName: "T4", unitAndRange: "4.5-11.2 µg/dL" },
            { fieldId: "83120-6", fieldName: "fT4", unitAndRange: "0.8-1.8 ng/dL" },
            { fieldId: "4548-4", fieldName: "TSH WB", unitAndRange: "0.4-4.0 µIU/mL" },
            { fieldId: "4548-4", fieldName: "TSH", unitAndRange: "0.4-4.0 µIU/mL" },
        ],
    },
    {
        testFieldName: "Respiratory",
        fields: [
            { fieldId: "", fieldName: "RSV Ag", unitAndRange: "Negative" },
            { fieldId: "", fieldName: "Legionella Ag", unitAndRange: "Negative" },
            { fieldId: "", fieldName: "S. Pneumonia", unitAndRange: "Negative" },
            { fieldId: "", fieldName: "Covid Antigen", unitAndRange: "Negative" },
            { fieldId: "", fieldName: "TSH WB", unitAndRange: "0.4-4.0 µIU/mL" },
            { fieldId: "", fieldName: "Strep A Ag", unitAndRange: "Negative" },
        ],
    },
    {
        testFieldName: "Rapid",
        fields: [
            { fieldId: "", fieldName: "Typhoid", unitAndRange: "Negative" },
            { fieldId: "", fieldName: "Cholera", unitAndRange: "Negative" },
            { fieldId: "", fieldName: "HIV 1&2", unitAndRange: "Negative" },
            { fieldId: "", fieldName: "HBsAG", unitAndRange: "Negative" },
            { fieldId: "", fieldName: "HCV", unitAndRange: "Negative" },
            { fieldId: "", fieldName: "Malaria", unitAndRange: "Negative" },
            { fieldId: "", fieldName: "Dengue", unitAndRange: "Negative" },
            { fieldId: "", fieldName: "Chikungunya", unitAndRange: "Negative" },
            { fieldId: "", fieldName: "Leptospira", unitAndRange: "Negative" },
            { fieldId: "", fieldName: "Elephantiasis", unitAndRange: "Negative" },
            { fieldId: "", fieldName: "Leishmania", unitAndRange: "Negative" },
            { fieldId: "", fieldName: "Urine Dip Test", unitAndRange: "Negative" },
        ],
    },
    {
        testFieldName: "Others",
        fields: [
            { fieldId: "", fieldName: "iFOB", unitAndRange: "Negative" },
            { fieldId: "", fieldName: "Electro Cardiogram", unitAndRange: "Normal" },
        ],
    },
    {
        testFieldName: "Vector Borne",
        fields: [
            { fieldId: "", fieldName: "Dengue NS1 AG", unitAndRange: "Negative" },
            { fieldId: "", fieldName: "Dengue IgM/IgG", unitAndRange: "Negative" },
            { fieldId: "", fieldName: "Chikungunya IgM/IgG", unitAndRange: "Negative" },
        ],
    },
    {
        testFieldName: "Complete Blood Count",
        fields: [
            { fieldId: "", fieldName: "Total Leucocyte Count", unitAndRange: "4.5 - 10.5 10^3/µL" },
            { fieldId: "", fieldName: "RBC Count", unitAndRange: "4.2 - 6.1 mill/µL" },
            { fieldId: "", fieldName: "Hemoglobin", unitAndRange: "12.0 - 17.5 g/dL" },
            { fieldId: "", fieldName: "Hematocrit", unitAndRange: "36.0 - 50.0 %" },
            { fieldId: "", fieldName: "Mean Corpuscular Volume", unitAndRange: "80 - 100 fL" },
            { fieldId: "", fieldName: "Mean Corpuscular Hemoglobin", unitAndRange: "27.5 - 33.2 pg" },
            { fieldId: "", fieldName: "Mean Corpuscular Hemoglobin Concentration", unitAndRange: "33.4 - 35.5 g/dL" },
            { fieldId: "", fieldName: "RBC Distribution Width - CV", unitAndRange: "11.5 - 15.4 %" },
            { fieldId: "", fieldName: "RBC Distribution Width - SD", unitAndRange: "35.0 - 56.0 fL" },
            { fieldId: "", fieldName: "Platelet Count", unitAndRange: "150.0 - 450.0 10^3/µL" },
            { fieldId: "", fieldName: "Mean Platelet Volume", unitAndRange: "8.6 - 15.5 fL" },
            { fieldId: "", fieldName: "Plateletcrit", unitAndRange: "0.20 - 0.36 %" },
            { fieldId: "", fieldName: "Platelet Distribution Width - CV", unitAndRange: "8.1 - 20.0 %" },
            { fieldId: "", fieldName: "Platelet Distribution Width - SD", unitAndRange: "9.0 - 17.0 fL" },
            { fieldId: "", fieldName: "Platelet Large Cell Ratio", unitAndRange: "11.0 - 45.0 %" },
            { fieldId: "", fieldName: "Platelet Large Cell Count", unitAndRange: "30.0 - 90.0 10^3/µL" },
            { fieldId: "", fieldName: "Lymphocytes %", unitAndRange: "18.0 - 45.0 %" },
            { fieldId: "", fieldName: "Lymphocyte #", unitAndRange: "0.8 - 5.0 10^3/µL" },
            { fieldId: "", fieldName: "MidCell %", unitAndRange: "2.0 - 8.0 %" },
            { fieldId: "", fieldName: "MidCell #", unitAndRange: "0.1 - 0.8 10^3/µL" },
            { fieldId: "", fieldName: "Granulocytes %", unitAndRange: "51.4 - 74.0 %" },
        ],
    },
    {
        testFieldName: "Cough",
        fields: [
            { fieldId: "", fieldName: "Test-COUGH", unitAndRange: "Varies by test" },
        ],
    },
    {
        testFieldName: "Urinalysis",
        fields: [
            { fieldId: "", fieldName: "Urine Test", unitAndRange: "Varies by parameter" },
        ],
    },
    {
        testFieldName: "Infection Marker",
        fields: [
            { fieldId: "48421-2", fieldName: "CRP", unitAndRange: "0-10 mg/L" },
            { fieldId: "SDB-0260", fieldName: "PCT", unitAndRange: "0-0.5 ng/mL" },
        ],
    },
];

export default formattedTranslations;