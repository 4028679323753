import React from 'react'
import { Outlet } from 'react-router-dom'
import OrderSummary from './OrderSummary'

export default function PaymentLayout() {
    return (
        <div>
            <Outlet />

            <OrderSummary />
        </div>
    )
}
