import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import HomePage from '../Pages/Home';
import FAQ from '../Pages/FAQ';
import AuthLayout from '../Pages/auth/AuthLayout';
import ProtectedLayout from '../Pages/protected/ProtectedLayout';
import AddPatientdetails from '../Pages/protected/AddPatientDetails';
import AddPatient from '../Pages/protected/AddPatient';
import Tests from '../Pages/protected/Tests';
import TestPage from '../Pages/protected/TestPage';
import ReportPage from '../Pages/protected/ReportPage';
import SystemPage from '../Pages/protected/SystemPage';
import PaymentLayout from '../Pages/protected/payment/PaymentLayout';
import Method1 from '../Pages/protected/payment/Method1';
import Method2 from '../Pages/protected/payment/Method2';
import AddressProof from '../Pages/protected/payment/AddressProof';
import Confirmation from '../Pages/protected/payment/Confirmation';
import LoginPage from '../Pages/auth/LoginPage';
import GeneralTest from '../Pages/protected/GeneralTest'
import ForgotPassword from '../Pages/auth/ForgotPassword';
import BodyComposition from '../Pages/protected/BodyComposition';
import SearchPatient from '../Pages/protected/SearchPatient'
import PrivacyPolicy from '../Pages/PrivacyPolicy'
import Notification from '../Pages/protected/Notification';

const AllRoutes = () => {
  return (
    <Routes>
      {/* Public Routes */}

      <Route path="/faq" element={<FAQ />} />
      <Route path="/privacypolicy" element={<PrivacyPolicy />} />

      {/* Authentication Routes */}
      <Route path="/auth" element={<AuthLayout />}>
        <Route path="login" element={<LoginPage />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
      </Route>

      {/* Protected Routes */}
      <Route path="/" element={<ProtectedLayout />}>
        {/* Default route if base path is accessed */}
        <Route path="add-patient" element={<AddPatient />} />
        <Route path="add-patientdetails" element={<AddPatientdetails />} />
        <Route path="tests" element={<Tests />} />
        <Route path="tests/:testId" element={<TestPage />} />
        <Route path="tests/:testId/report" element={<ReportPage />} />
        <Route path="system" element={<SystemPage />} />
        <Route path="general-test" element={<GeneralTest />} />
        <Route path="search-patient" element={<SearchPatient />} />
        <Route path="notifications" element={<Notification />} />
        <Route path="body-composition" element={<BodyComposition />} />



        {/* Nested Payment Routes */}
        <Route path="payment" element={<PaymentLayout />}>
          <Route path="method1" element={<Method1 />} />
          <Route path="method2" element={<Method2 />} />
          <Route path="address" element={<AddressProof />} />
          <Route path="confirmation" element={<Confirmation />} />
        </Route>
      </Route>

      <Route path="*" element={<Navigate to="/home" replace />} />
    </Routes>
  );
};

export default AllRoutes;
