import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    patientData: null
}

const patientSlice = createSlice({
    name: 'patientSlice',
    initialState,
    reducers: {
        addPatient: (state, action) => {
            state.patientData = action.payload;
        }
    }
})

export const { addPatient } = patientSlice.actions;
export default patientSlice.reducer