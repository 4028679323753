import React, { useState } from 'react';

import help from "../assets/help.svg";
import sidevector from "../assets/sidevector.svg";
import Navbar from '../Components/Navbar';

export default function FAQ() {
  const [openIndex, setOpenIndex] = useState(null);

  const faqs = [
    {
      question: "What skills are essential for a technician?",
      answer: "Technical skills, attention to detail, and problem-solving abilities are essential for a technician.",
    },
    {
      question: "What safety measures should I be aware of work?",
      answer: "Always wear protective gear, follow safety protocols, and stay informed about potential hazards.",
    },
    {
      question: "What is the ideal process?",
      answer: "The ideal process involves planning, executing, monitoring, and evaluating tasks efficiently and effectively.",
    },
    {
      question: "What are the best practices?",
      answer: "Best practices include continuous learning, effective communication, and consistent adherence to standards.",
    },
    {
      question: "What should I do if I encounter a problem?",
      answer: "Consult relevant resources, seek guidance from colleagues or supervisors, and be proactive in expanding your knowledge.",
    },
  ];

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <>
    {/* Navbar */}
    <Navbar/>
    <div className="bg-[#C7CEE5] min-h-screen py-10 px-6 lg:px-28 relative">
      {/* FAQ Section */}
      <div className=" p-4 lg:p-8 rounded-sm relative z-10 shadow-xl">
        <h1 className="text-2xl lg:text-[40px] font-semibold text-center text-[#845BB3] mb-8">
          FREQUENTLY ASKED QUESTIONS 'FAQS'
        </h1>
        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className={`bg-white p-4 rounded-sm shadow-md cursor-pointer ${openIndex === index && "bg-[#F3F3FB]"}`}
              onClick={() => handleToggle(index)}
            >
              <div className="flex justify-between items-center">
                <h2 className={`text-lg font-semibold text-[#845BB3]`}>
                  {faq.question}
                </h2>
                <span className="text-2xl text-[#845BB3]">
                  {openIndex === index ? "-" : "+"}
                </span>
              </div>
              {openIndex === index && (
                <p className="text-black font-semibold mt-2">
                  {faq.answer}
                </p>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Background Image */}
      <div className="absolute bottom-0 z-0 right-0 w-full lg:w-auto">
        <img src={sidevector} alt="sidevector" className="w-full lg:w-auto" />
      </div>

      {/* Help Icon */}
      <div className="fixed bottom-8 right-8 lg:right-20">
        <img src={help} alt="helpicon" className="w-10 lg:w-12" />
      </div>
    </div>
    </>
  );
}
