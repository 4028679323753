// src/pages/SearchPatient.js

// Import necessary components and hooks
import { useState } from "react";
import useFetchPatients from "../../Hooks/SearchPatient";
import PatientCard from "../../Components/PatientCard";
import Navbar from "../../Components/Navbar";
import sidevector from "../../assets/sidevector.svg";

const SearchPatient = () => {
  const [searchInput, setSearchInput] = useState(""); // State to manage the search input
  const { data: patients, loading, error } = useFetchPatients(searchInput); // Pass searchInput to the custom hook

  // Handle input change to update search term
  const handleInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  // Render loading state
  if (loading) {
    return <p className="text-center mt-4">Loading...</p>;
  }

  // Render error state
  if (error) {
    return (
      <p className="text-center text-red-500 mt-4">Error: {error.message}</p>
    );
  }

  return (
    <div className="min-h-screen w-full bg-[#C7CEE5] relative">
      {/* Navbar */}
      <Navbar searchInput={searchInput} handleInputChange={handleInputChange} />

      {/* Main Content */}
      <div className="flex flex-col gap-y-8 z-10">
        <div className="flex flex-col items-center z-10 justify-center py-6 my-10">
          <div className="grid grid-cols-4 gap-6 w-[1246px] h-auto">
            {patients?.length > 0 ? (
              patients.map((patient) => (
                <PatientCard key={patient.PatientID} patient={patient} />
              ))
            ) : (
              <p className="text-center text-gray-500 col-span-4">No patients found.</p>
            )}
          </div>
        </div>
      </div>

      {/* Background Image */}
      <div className="absolute bottom-0 right-0 z-0 w-full lg:w-auto">
        <img src={sidevector} alt="sidevector" className="w-full lg:w-auto" />
      </div>
    </div>
  );
};

export default SearchPatient;
