import React, { useState  , useEffect } from "react";
import HeartRateImage from "../../assets/icons/heart-rate.svg";
import HeartRate from "../../assets/icons/heart-rate-2.svg";
import Temp from "../../assets/icons/temp.svg";
import BloodPressure from "../../assets/icons/blood-pressure.svg";
import BloodAnalysis from "../../assets/icons/body-analysis.svg";
import Weight from "../../assets/icons/weight.svg";
import Spo2Icon from "../../assets/icons/spo2Icon.svg";
import spo2Image from "../../assets/icons/spo2.svg";
import DefaultBodyCompositionImage from "../../assets/default-body-composition-image.png";
import BodyCompositionImage1 from "../../assets/body-composition-image1.png";
import BodyCompositionImage2 from "../../assets/body-composition-image2.png";
import BodyCompositionImage3 from "../../assets/body-composition-image3.png";
import HeightIcon from "../../assets/icons/height.svg";
import angleDown from "../../assets/icons/downarrow.svg";
import axios from "axios";

export default function BodyComposition() {
  const arrayImages = [
    DefaultBodyCompositionImage,
    BodyCompositionImage1,
    BodyCompositionImage2,
    BodyCompositionImage3,
  ];
  const [currentImage, setCurrentImage] = useState(BodyCompositionImage1);
  const [intervalId, setIntervalId] = useState(null); // To track the interval ID
  const [isOpen, setIsOpen] = useState(false);
  const [dropValue, setDropValue] = useState("Body Type");
  const [heartRate, setHeartRate] = useState("HeartRate");
  const [res, setres] = useState(" ");
  const [temperature, setTemperature] = useState("Temperature")
  const [bloodPressure, setbloodPressure] = useState("BloodPressure")
  const [form, setForm] = useState({
    user_id: "user",
    name: "Jsohann Dsinghoe",
    gender: "Male",
    age: 56,
    body_type: "Average",
  });
  const [data, setData] = useState(null);

  //handle heart
  
  const handleDropdownSelect = async (value) => {
    setDropValue(value);
    setIsOpen(false);
    
    try {
      const response = await axios.post('http://localhost:3003/api/data', {
        user_id: form.user_id,
        name: form.name,
        gender: form.gender,
        age: form.age,
        body_type: form.body_type,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      
      const result = await response.data;
      console.log('Response from server:', result);
    } catch (error) {
      console.error('Error message:', error.message);
      setData({ feedback: 'Failed to fetch data' });
    }
  };
  
  useEffect(() => {
    const fetchDataFromServer = async () => {
      try {
        const response = await fetch('http://localhost:3003/api/res');
        const data = await response.json();
        console.log('Data from server:', data);
        setres(data); // Update state with fetched data
      } catch (error) {
        console.error('Error:', error);
      }
    };
    
    fetchDataFromServer();
  }, [data]);

  function handleHeart() {
    // Assuming `res.height` is defined elsewhere in your code
    handleAnimation();
    setTimeout(() => {
      setHeartRate(res.heartRate); // Reset to default after 2 seconds
    }, 4000);
  }

  function handleTemperature() {
    // Assuming `res.height` is defined elsewhere in your code
    handleAnimation();
    setTimeout(() => {
      setTemperature(res.temperature) // Reset to default after 2 seconds
    }, 4000);
  }


  function handleBloodPressure() {
    // Assuming `res.height` is defined elsewhere in your code
    handleAnimation();
    setTimeout(() => {
      setbloodPressure(res.bloodPressure); // Reset to default after 2 seconds
    }, 4000);
   
  }

  function handleAnimation() {
    let index = 1;

    // Clear any existing intervals
    if (intervalId) {
      clearInterval(intervalId);
    }

    // Start a new interval
    const newIntervalId = setInterval(() => {
      setCurrentImage(arrayImages[index]);
      index++;

      if (index === arrayImages.length) {
        clearInterval(newIntervalId); // Clear interval after last image
        setTimeout(() => {
          setCurrentImage(DefaultBodyCompositionImage); // Reset to default after 2 seconds
        }, 2000);
      }
    }, 2000); // Change every 2 seconds

    setIntervalId(newIntervalId);
  }


  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="mt-14 font-popins">
      <div className="w-full max-w-[80rem] mx-auto">
        <h1 className="text-3xl px-10 font-extrabold text-[#845BB3]">
          BODY COMPOSITION
        </h1>

        <div className="flex items-start justify-around">
          <div className="p-4 m-10 w-full max-w-fit flex relative">
            <div
              
              className="flex flex-col gap-8 justify-center cursor-pointer absolute left-0"
            >
              <div onClick={handleHeart} className="flex flex-col items-center">
                <img className="w-14 h-14" src={HeartRate} alt="Heart Rate" />
                <p className="text-[#845BB3] text-sm px-2 py-1 rounded-md border border-[#845BB3] w-fit mt-2">
                {heartRate}
                </p>
              </div>

              <div
                onClick={handleTemperature}
                className="flex flex-col items-center mr-19 mt-6"
              >
                <img className="w-14 h-14" src={Temp} alt="Temperature" />
                <p className="text-[#845BB3] text-sm px-2 py-1 rounded-md border border-[#845BB3] w-fit mt-2">
                {temperature}
                </p>
              </div>

              <div
                onClick={handleBloodPressure}
                className="flex flex-col items-center"
              >
                <img className="w-14 h-14" src={BloodPressure} alt="Blood Pressure" />
                <p className="text-[#845BB3] text-sm px-2 py-1 rounded-md border border-[#845BB3] w-fit mt-2">
                  {bloodPressure}
                </p>
              </div>
            </div>

            <div className="flex flex-col gap-2">
              {/* Ensure images are consistently sized */}
              <img
                className="w-[30rem] h-[30rem] object-contain mx-auto"
                src={currentImage}
                alt="Current Body Composition"
              />

              <div className="flex flex-col items-center mt-6">
                <img className="w-14 h-14" src={BloodAnalysis} alt="Body Analysis" />
                <p className="text-[#845BB3] text-sm px-2 py-1 rounded-md border border-[#845BB3] w-fit mt-2">
                  Body Analysis
                </p>
              </div>

              <div className="flex flex-col items-center">
                <button className="text-red-500 text-sm px-2 py-1 rounded-md border border-red-500 w-fit mt-2">
                  Reset
                </button>
              </div>
            </div>

            <div className="flex flex-col gap-8 justify-center absolute right-0">
              <div
                onClick={handleAnimation}
                className="flex flex-col items-center"
              >
                <img className="w-14 h-14" src={HeightIcon} alt="Height" />
                <p className="text-[#845BB3] text-sm px-2 py-1 rounded-md border border-[#845BB3] w-fit mt-2">
                  Height
                </p>
              </div>

              <div
                onClick={handleAnimation}
                className="flex flex-col items-center ml-16"
              >
                <img className="w-14 h-14" src={Weight} alt="Weight" />
                <p className="text-[#845BB3] text-sm px-2 py-1 rounded-md border border-[#845BB3] w-fit mt-2">
                  Weight
                </p>
              </div>

              <div
                onClick={handleAnimation}
                className="flex flex-col items-center mt-10 ml-4"
              >
                <img className="w-14 h-14" src={Spo2Icon} alt="SpO2" />
                <p className="text-[#845BB3] text-sm px-2 py-1 rounded-md border border-[#845BB3] w-fit mt-2">
                  SpO2
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col">
            <div className="w-96 mt-10">
              <div
                onClick={toggleDropdown}
                className="flex items-center justify-between border border-[#845BB3] rounded-md cursor-pointer px-2 py-1"
              >
                <p className="text-[#845BB3] text-sm">{dropValue}</p>
                <img className="w-6 h-6" src={angleDown} alt="Dropdown Icon" />
              </div>

              {isOpen && (
                <div className="mt-2 border border-[#845BB3] rounded-md bg-white shadow-md">
                  {['athelete', 'Average', 'Fat'].map((option) => (
                    <div
                      key={option}
                      onClick={() => handleDropdownSelect(option)}
                      className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                    >
                      {option}
                    </div>
                  ))}
                </div>
              )}
            </div>

         
          </div>
        </div>

        {data && (
          <div className="mt-6 p-4 border border-gray-200 rounded-md">
            <h2 className="text-xl font-semibold">Fetched Data:</h2>
            <pre>{JSON.stringify(data, null, 2)}</pre>
          </div>
        )}
      </div>
    </div>
  );
}

