import React, { useState } from 'react';
import SignupTopImage from '../../assets/signup-top-image.png';
import SuperceuticalsLogo from '../../assets/superceuticals-logo.png';
import UserIcon from '../../assets/icons/user.svg';
import MonitorIcon from '../../assets/icons/monitor.svg';
import LockIcon from '../../assets/icons/lock.svg';
import { Link, useNavigate } from 'react-router-dom';
import API_ENDPOINTS from '../../service/api';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import toastify CSS
import { useDispatch } from 'react-redux';
import { loginReducer } from '../../Redux_Services/slices/authSlice';

export default function LoginPage() {
    const [form, setForm] = useState({
        technicianId: '',
        MachineId: '',
        password: '',
        rememberMe: false,
    });
    const navigate = useNavigate();
    const dispatch = useDispatch()

    function handleChangeEvent(event) {
        event.preventDefault();
        const { name, value } = event.target;

        setForm((form) => ({
            ...form,
            [name]: value,
        }));
    }

    async function handleSubmit() {
        if (
            form.technicianId.trim() &&
            form.MachineId.trim() &&
            form.password.trim()
        ) {
            try {
                const resp = await axios.post(API_ENDPOINTS.signUpApi, {
                    LabTechnicianID: form.technicianId,
                    MachineID: form.MachineId,
                    password: form.password,
                });

                if (!resp?.data.error) {
                    dispatch(loginReducer(resp.data))
                    localStorage.setItem('cookieFallback', resp.data.token)

                    toast.success('Login successful!', {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    navigate('/');
                } else {
                    toast.error('Login failed. Please check your credentials.', {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            } catch (error) {
                console.log(error.response.data.message);
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        } else {
            toast.error('Please fill out all fields.', {
                position: toast.POSITION.TOP_CENTER,
            });
        }
    }

    return (
        <div className="bg-white w-full h-full max-w-[400px] max-h-[750px] mr-32 p-5 rounded-[30px] relative overflow-hidden border-none font-popins select-none">
            <img
                src={SignupTopImage}
                style={{ borderRadius: '10% 30% 60% 60%' }}
                alt="Top Background"
                className="absolute top-[-130px] left-0 w-full max-w-[784px] h-[284px] object-cover"
            />

            <div className="w-full h-full mt-40">
                <img src={SuperceuticalsLogo} className="max-w-[18rem] mx-auto" />

                <div className="w-full max-w-fit mx-auto mt-10 text-center text-[#845BB3]">
                    <h1 className="font-bold text-4xl">SC-AN</h1>
                    <h2 className="font-semibold text-2xl mt-1">Technician</h2>
                </div>

                <div className="w-full max-w-sm mx-auto mt-6 flex flex-col gap-5">
                    <span className="w-full border border-gray-400 px-2 py-3 flex items-center gap-4 rounded-md focus-within:border-purple-400">
                        <img src={UserIcon} />
                        <input
                            type="text"
                            name="technicianId"
                            onChange={handleChangeEvent}
                            className="w-full outline-none group-active:border-purple-400 text-gray-400 placeholder:font-semibold text-sm"
                            placeholder="Technician ID"
                        />
                    </span>

                    <span className="w-full border border-gray-400 px-2 py-3 flex items-center gap-4 rounded-md focus-within:border-purple-400">
                        <img src={MonitorIcon} />
                        <input
                            type="text"
                            name="MachineId"
                            onChange={handleChangeEvent}
                            className="w-full outline-none group-active:border-purple-400 text-gray-400 placeholder:font-semibold text-sm"
                            placeholder="Machine ID"
                        />
                    </span>

                    <span className="w-full border border-gray-400 px-2 py-3 flex items-center gap-4 rounded-md focus-within:border-purple-400">
                        <img src={LockIcon} />
                        <input
                            type="password"
                            name="password"
                            onChange={handleChangeEvent}
                            className="w-full outline-none group-active:border-purple-400 text-gray-400 placeholder:font-semibold text-sm"
                            placeholder="Password"
                        />
                    </span>

                    <div className="text-xs flex items-center justify-between">
                        <span className="flex items-center gap-2">
                            <input
                                id="remember-me"
                                type="checkbox"
                                checked={form.rememberMe}
                                onChange={() =>
                                    setForm({
                                        ...form,
                                        rememberMe: !form.rememberMe,
                                    })
                                }
                            />
                            <label htmlFor="remember-me" className="cursor-pointer">
                                Remember me
                            </label>
                        </span>

                        <Link className="text-[#845BB3] font-semibold" to="/auth/forgot-password">
                            Forgot password ?
                        </Link>
                    </div>

                    <button
                        onClick={handleSubmit}
                        className="bg-[#845BB3] text-white font-bold py-3 mt-5 rounded-md"
                    >
                        Sign in
                    </button>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}
