import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slices/authSlice";
import testSlice from "./slices/testSlice";
import patientSlice from "./slices/patientSlice"

const store = configureStore({
    reducer: {
        authSlice,
        testSlice,
        patientSlice
    }
})

export default store