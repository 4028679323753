
// src/hooks/useDebounce.js
import { useState, useEffect } from 'react';

// useDebounce hook to delay the input updates
const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    // Set a timeout to update the debounced value
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // Cleanup timeout if value or delay changes
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);


  return debouncedValue;
};

export default useDebounce;
