import React, { useState, useEffect } from "react";
import reload from "../../assets/icons/reload.svg";
import beaker1 from "../../assets/icons/beaker-1.svg";
import beaker2 from "../../assets/icons/beaker-2.svg";
import report from "../../assets/icons/report.svg";
import compass from "../../assets/icons/compass.svg";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { addTests, addReferedBy } from "../../Redux_Services/slices/testSlice";
import axios from "axios"; // Import axios

const GeneralTest = () => {
  const dispatch = useDispatch();
  const tests = useSelector((state) => state.testSlice.testsData || []);
  const referedBy = useSelector((state) => state.testSlice.referedBy);
  const [testData, setTestData] = useState(tests);
  const [referby, setReferby] = useState("");

  useEffect(() => {
    setTestData(tests);
    setReferby(referedBy);
  }, [tests, referedBy]);

  const navigate = useNavigate();

  const handleInputChange = (sectionIndex, fieldIndex, value) => {
    const updatedTestData = testData.map((test, sIndex) => {
      if (sIndex === sectionIndex) {
        return {
          ...test,
          fields: test.fields.map((field, fIndex) => {
            if (fIndex === fieldIndex) {
              return {
                ...field,
                value: value,
              };
            }
            return field;
          }),
        };
      }
      return test;
    });
    setTestData(updatedTestData);
  };

  const handleReferBy = (e) => {
    setReferby(e.target.value);
  };

  // Implement the handlePreviousResults function using axios
  const handlePreviousResults = async () => {
    try {
      // Test codes to be sent in the request body
      const test_codes = [
        "49551-5",
        "42757-5",
        "71427-9",
        "SDB-0550",
        "30522-7",
      ];

      const response = await axios.post(
        "https://api.superceuticals.in/test-two/get-latest-legacy-test/MC0001",
        { test_codes: test_codes }, // Send test_codes in the body
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJOYW1lIjoiSkFJIFJBSiBTSElORyIsIlBhdGllbnRJRCI6IlNDMjRGMDAwMDQ1OCIsImlhdCI6MTcyMDg1OTM0Nn0._p27pt9SPFEJm7f7l0Edd0BYsB_RUoOnOPY1I4hb6yw",
          },
        }
      );

      const data = response.data;
      console.log(data);
      if (!data.error && data.getTest && data.getTest.length > 0) {
        // Build a map of test_code to result
        const resultMap = {};

        data.getTest.forEach((testItem) => {
          if (testItem.mydata && testItem.mydata.length > 0) {
            const testDataItem = testItem.mydata[0];
            const testCode = testDataItem.test_code;
            const result = testDataItem.result;

            resultMap[testCode] = result;
          }
        });

        // Update testData state
        const updatedTestData = testData.map((test) => {
          return {
            ...test,
            fields: test.fields.map((field) => {
              if (field.code && resultMap[field.code]) {
                return {
                  ...field,
                  value: resultMap[field.code] || field.value,
                };
              }

              return field;
            }),
          };
        });

        setTestData(updatedTestData);
        console.log(updatedTestData);
      } else {
        alert("No previous results found.");
      }
    } catch (error) {
      console.error("Error fetching previous results:", error);
      if (error.response) {
        console.error("Server responded with:", error.response.data);
      }
      alert("Failed to fetch previous results.");
    }
  };

  const handleSave = () => {
    dispatch(addTests(testData));
    dispatch(addReferedBy(referby));
    navigate("/tests/testId/report");
  };

  return (
    <div className="flex flex-col overflow-x-hidden items-center justify-center h-screen w-screen m-0">
      <div className="flex flex-col w-[80%] h-[85%] bg-white rounded-2xl my-5 mx-auto">
        <div className="relative w-full h-[15%] bg-[#845BB3] rounded-t-2xl flex items-center justify-center">
          <div className="font-poppins text-2xl font-semibold text-white">
            LATEST TEST RESULT
          </div>
        </div>

        <div className="w-full h-[25%] max-h-16 flex overflow-hidden">
          <div className="flex-grow-[4] border-r border-b border-[#845BB3] flex justify-center items-center">
            <img src={beaker1} alt="icon" className="w-7 h-7 mr-2" />
            <p className="text-center">Test Name</p>
          </div>

          <div className="flex-grow border-r border-b border-[#845BB3] flex justify-center items-center">
            <img src={beaker2} alt="icon" className="w-6 h-6 mr-2" />
            <p className="text-center">Results</p>
          </div>

          <div className="flex-grow border-b border-[#845BB3] flex justify-center items-center">
            <img src={compass} alt="icon" className="w-6 h-6 mr-2" />
            <p className="text-center">Unit and Range</p>
          </div>
        </div>

        <div className="flex-grow w-full flex flex-col gap-4 p-4 overflow-y-auto">
          {testData.map((test, sectionIndex) => (
            <div key={sectionIndex} className="w-full flex flex-col gap-4">
              <div className="flex items-center mb-4">
                <button className="bg-[#D7D4F8] rounded-md text-center px-4 py-2 font-poppins font-semibold text-[#845BB3] text-xl">
                  {test.testFieldName}
                </button>
              </div>

              <div className="w-full flex flex-col gap-4">
                {test.fields.map((field, index) => (
                  <div key={index} className="flex items-center">
                    <div className="w-2/3 text-left px-2 py-1 flex items-center font-poppins text-lg font-medium text-[#845BB3]">
                      {field.fieldName}
                    </div>
                    <input
                      type="text"
                      value={field.value || ""}
                      onChange={(e) =>
                        handleInputChange(sectionIndex, index, e.target.value)
                      }
                      className="w-1/6 rounded-md bg-[#D7D4F8] text-center px-3 py-2 text-base outline-none"
                    />
                    <div className="w-1/4 text-right px-4 py-1 font-poppins text-sm font-medium text-[#845BB3]">
                      {field.unitAndRange}
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex items-center mt-4">
                <button className="bg-[#845BB3] rounded-md text-white text-sm font-semibold py-2 px-4 flex items-center">
                  <span className="mr-2">RESULTS</span>
                  <img src={reload} alt="icon" className="w-5 h-5" />
                </button>
              </div>
              <div className="flex items-center mt-4">
                <button
                  className="bg-[#845BB3] rounded-md text-white text-sm font-semibold py-2 px-4 flex items-center"
                  onClick={handlePreviousResults}
                >
                  <span className="mr-2"> PREVIOUS RESULTS</span>
                  <img src={reload} alt="icon" className="w-5 h-5" />
                </button>
              </div>

              {sectionIndex < testData.length - 1 && (
                <div className="w-full border-t-2 border-[#845BB3] mb-4"></div>
              )}
            </div>
          ))}
        </div>

        <div className="flex w-full justify-between items-center py-6 px-5 border-t-2">
          <div className="flex flex-row justify-start items-center">
            <p className="text-2xl text-[#845BB3]">REFERRED BY:</p>
            <input
              type="text"
              className="w-1/2 rounded-md bg-[#D7D4F8] text-center ml-2 px-3 py-2 text-base outline-none"
              onChange={handleReferBy}
              value={referby} // controlled input
            />
          </div>
          <div>
            <button
              onClick={handleSave}
              className="bg-[#AB5C5C] rounded-md text-white text-base font-semibold py-2 px-2 flex items-center"
            >
              <span className="mr-2">GENERATE REPORT</span>
              <img src={report} alt="icon" className="w-6 h-6 align-middle" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralTest;
