// src/Components/Navbar.js
import React from 'react';
import { Link, useLocation } from 'react-router-dom'; // Import useLocation hook
import home from "../assets/home.svg";
import bell from "../assets/bell.svg";
import add from "../assets/add.svg";
import logout from "../assets/logout.svg";
import search from "../assets/search.svg";
import image from "../../src/assets/image.png";
import { useNavigate } from 'react-router-dom';


export default function Navbar({ searchInput, handleInputChange }) {
  const location = useLocation(); // Get the current location
  const navigate  = useNavigate();
  const homeClick=()=>{
    navigate('/home');
  }
  const handlePatientClick=()=>{
    navigate('/add-patientdetails');
  }
  const searchClick=()=>{
    navigate('/search-patient');
  }
  const logoutClick=()=>{
    localStorage.removeItem('cookieFallback')
    navigate('/auth/login');
  }
  return (
    <div className="bg-[#C7CEE5] pt-10 px-28">
      {/* Navbar */}
      <nav className="flex justify-between items-center px-8">
        {/* Logo and Search */}
        <div className="flex items-center">
          <div className="text-4xl text-[#9669E0] font-bold">SC-AN</div>

          {/* Conditionally render search input or icon based on route */}
          {location.pathname === '/search-patient' ? (
            <div className="relative  max-w-xs ml-10">
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <img src={search} alt="search icon" className="w-4 h-4" />
              </span>
              <input
                type="text"
                value={searchInput} // Bind the value to searchInput prop
                onChange={handleInputChange} // Bind the onChange event to handleInputChange prop
                placeholder="Search"
                className="pl-10 pr-4 py-2 w-full bg-transparent border-2 border-[#9669E0] rounded-lg focus:outline-none focus:ring-1 focus:ring-purple-500"
              />
            </div>
          ) : (
            <button onClick={searchClick} className="flex items-center ml-6">
              <img src={search} className="w-8 h-8" alt="search icon" />
            </button>
          )}
        </div>

        {/* Icons */}
        <div className="flex items-center space-x-20">
          <Link to={'/add-patient'} onClick={homeClick}>
            <img src={home} className="w-8 h-8" alt="home icon" />
          </Link>
          <Link to={'/notifications'} >
            <img src={bell} className="w-8 h-8" alt="notification icon" />
          </Link>
          <button onClick={handlePatientClick}>
            <img src={add} className="w-8 h-8" alt="add icon" />
          </button>
          <Link to={'/add-patient'}>
            <img
              src={image}
              className="rounded-full w-8 h-8 p-1 border-2 border-[#9669E0]"
              alt="profile icon"
            />
          </Link>
          <button onClick={logoutClick}>
            <img src={logout} className="w-8 h-8" alt="logout icon" />
          </button>
        </div>
      </nav>
    </div>
  );
}
